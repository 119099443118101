import useSWR, { SWRResponse } from 'swr';
import { getData } from '@datavant/idsb-shared/utils';
import pino from 'pino';
import { NextRouter, useRouter } from 'next/router';
import { useContext } from 'react';
import { RefreshCompaniesContext } from '../components/SmartRequest/RefreshCompaniesProvider';

export type User = {
    email: string;
    smartRequestUserData: CurrentSmartRequestUser;
};

export type SmartRequestUserRoles = {
    admin: boolean;
    addNewRequest: boolean;
    payment: boolean;
    viewDownload: boolean;
    active: boolean;
    superAdmin: boolean;
};

export type SmartRequestUserData = {
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    title: string;
    clientSideFlags?: {
        useV2paginatedRequestsApi: boolean;
        useCleanseAddressModal: boolean;
        dontPassFileSizeOnDownload: boolean;
        showImprovedPaymentsErrorMessage: boolean;
        showCompanySettingsUpdates: boolean;
        oktaToolsAccess: boolean;
        showMaintenancePage: boolean;
        allowSuperUsersEditCustId: boolean;
    };
} & SmartRequestUserRoles;

export type SmartRequestRetentionPeriod = {
    days: string;
    startDate: string;
    endDate?: string;
};
export type SmartRequestCompany = {
    id: number;
    name: string;
    address1: string;
    city: string;
    state: string;
    zip: string;
    majorClass: string;
    archivalHistory: SmartRequestRetentionPeriod[];
};

export type SmartRequestDisclaimerStatus = {
    disclaimer: boolean;
    privacyPolicy: boolean;
    tnC: boolean;
};

export type SmartRequestUserWithCompanies = {
    companies: SmartRequestCompany[];
} & SmartRequestUserData;

export type CurrentSmartRequestUser = {
    notifications: {
        emails: boolean;
    };
    disclaimerStatus: SmartRequestDisclaimerStatus;
} & SmartRequestUserWithCompanies;

const logger = pino().child({ name: 'user' });

const fetchUser = async (url: string, refreshId: string, router: NextRouter): Promise<User> => {
    try {
        const result = await getData(url, {}, logger);
        const companies = await getData(
            `/api/companies?email=${result.email}&refreshId=${refreshId}`,
            {},
            logger
        );
        if (typeof result !== 'object') {
            throw new Error(`Got back ${typeof result}, not an object as expected`);
        }

        if (router.pathname === '/error/400' || router.pathname === '/error/500') {
            await router.push('/');
        }

        const showMaintenancePage = result?.smartRequestUserData?.clientSideFlags?.showMaintenancePage;
        if (showMaintenancePage) {
            await router.push('/error/503');
        }

        if (router.pathname === '/error/503' && !showMaintenancePage) {
            await router.push('/');
        }

        return {
            ...result,
            smartRequestUserData: {
                ...result.smartRequestUserData,
                companies: companies,
            },
        };
    } catch (err) {
        if (err.response.status < 500) {
            await router.push('/error/400');
        } else {
            await router.push('/error/500');
        }
    }
};

const onError = (err: Error): void => {
    logger.warn(err, 'User fetch error');
};

type UserResponse = SWRResponse<User> & {
    user: CurrentSmartRequestUser;
};

export const useUser = (): UserResponse => {
    const router = useRouter();
    const { refreshId } = useContext(RefreshCompaniesContext);
    const fetcher = (url: string): Promise<User> => fetchUser(url, refreshId, router);
    // Our middleware only allows access to pages on /open and /cb if auth isn't set.
    // If we're on one of those pages, don't even try to fetch as we'll just produce a 400.
    // It'd be nicer to base this on our actual auth state, but not available on the client.
    // We store it in a server-side cookie with Iron Session, so we can't see it here.
    const expectLoggedIn = !router.asPath.startsWith('/open') && !router.asPath.startsWith('/cb');

    const resp: UserResponse = useSWR<User>(
        // Pass expectLoggedIn as an arg so it's part of the cache key for SWR.
        // That way if it changes, we run the fetcher
        ['/api/current', expectLoggedIn],
        expectLoggedIn ? fetcher : () => undefined,
        {
            dedupingInterval: 10000,
            onError,
        }
    ) as UserResponse;
    resp.user = resp.data?.smartRequestUserData;
    return resp;
};

export enum UserStatusLookupId {
    'NEEDS_REVIEW' = 1,
    'CONVERTED',
    'APPROVED',
    'DECLINED',
    'IN_PROGRESS',
}
